import { USER_LOGOUT } from '../actions/commonActions';

const initialState = {
    isError: false,
    show: false,
    message: ''
};

export default ( state = initialState, action ) => {
    const newState = { ...state };
    switch ( action.type ) {
    case 'TOGGLE_NOTIFICATION':
        newState.isError = action.payload.isError;
        newState.show = action.payload.show;
        newState.message = action.payload.message;
        return newState;
    case 'RESET_NOTIFICATION':
        newState.isError = false;
        newState.show = false;
        newState.message = '';
        return newState;
    case USER_LOGOUT:
        return initialState;
    default:
        return state;
    }
};

