import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import Home from '../components/Home';
import Login from '../components/auth/Login';
import Editor from '../components/editor/Editor';
import Header from '../components/layout/Header';
import Loading from '../components/utils/Loading';
import Exporter from '../components/export/export';
import { login, logout } from '../modules/actions/authActions';
import { deleteLanguage, setPreferredLanguage } from '../modules/actions/actions';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/App.css';
import Filters from '../components/Filters';

class App extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            isFilterOpen: false
        };
    }

    toggleFilter = ( bool ) => {
        const { isFilterOpen } = this.state;
        this.setState({ isFilterOpen: bool !== undefined ? bool : !isFilterOpen });
    }

    render() {
        const { user = {}, base = [], languages = [], filters = {} } = this.props;
        const { isFilterOpen } = this.state;

        return <div className="App">
            <Header user={user} filters={filters} toggleFilter={ () => this.toggleFilter() } logout={ () => this.props.logout() } />
            <div className="clearfix"></div><br/>
            <Container fluid>
                { user?.token ?
                    <>
                        {isFilterOpen &&
                            <Filters
                                isFilterOpen={isFilterOpen}
                                toggleFilter={() => this.toggleFilter()}
                            /> }
                        <Switch>
                            <Route exact path="/" component={() => <Redirect to="/home" /> }/>
                            <Route exact path="/home" component={() => <Home base={ base }
                                languages = { languages }
                                deleteLanguage={ ( code, alias ) => this.props.deleteLanguage( code, alias ) }
                                setPreferredLanguage={ ( code, alias ) => this.props.setPreferredLanguage( code, alias ) } /> }
                            />
                            <Route exact path="/editor" component={ Editor } />
                            <Route path="/editor/:searchTerm" component={ Editor } />
                            <Route exact path="/export" component={ Exporter } />
                            <Route path="*" component={() => <Redirect to="/home" /> }/>
                        </Switch> </> : <>
                        <Suspense fallback={<div><Loading /></div>}></Suspense>
                        <Switch>
                            <Route path="/login" render={ () => <Login login={ this.props.login } loginError={ user?.loginError } /> } />
                            <Route path="*" component={() => <Redirect to="/login" /> } />
                        </Switch>
                    </>
                }
            </Container>
        </div>;
    }
}

const mapStateToProps = ( state, _props ) => {
    return {
        user: state?.auth,
        base: state?.reducer?.base,
        languages: state?.reducer?.languages,
        filters: state?.reducer?.filters
    };
};

const mapDispatchToProps = ( dispatch, props ) => ({
    login: ( username, password ) => dispatch( login( props.history, username, password )),
    logout: () => dispatch( logout( props.history )),
    deleteLanguage: ( code, alias ) => dispatch( deleteLanguage( code, alias )),
    setPreferredLanguage: ( code, alias ) => dispatch( setPreferredLanguage( code, alias ))
});

export default connect( mapStateToProps, mapDispatchToProps )( App );

