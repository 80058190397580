import { showToast } from '../../components/utils/helperFns';

const uploadBaseFile = ( file, dispatch ) => {
    dispatch({
        type: 'ADD_BASE_FILE',
        payload: file
    });
};

const uploadLangFile = ( file, base, dispatch ) => {
    const baseLangFile = formLangFile( base );
    compareLangs( baseLangFile, file );

    const { translations, alias, code } = file;

    if ( translations && alias && code ) {
        dispatch({
            type: 'ADD_LANG_FILE',
            payload: file
        });
    } else {
        showToast( 'error', 'Langauge File Uploaded is not in the Correct Format' );
    }

};

const compareLangs = ( baseFile, file ) => {
    const baseJson = baseFile['translations'];
    const langJson = file['translations'];

    for ( const key in langJson ) {
        if ( baseJson[key] === undefined ) {
            delete langJson[key];
        }
    }

    for ( const key in baseJson ) {
        if ( langJson[key] === undefined ) {
            langJson[key] = '';
        }
    }

};

const uploadLangFiles = ( langs, dispatch ) => {
    dispatch({
        type: 'UPDATE_LANG_FILES',
        payload: langs
    });
};

export const addLangFile = ( alias, code, base ) => dispatch => {
    const langFile = formLangFile( base );
    dispatch({
        type: 'ADD_LANG_FILE',
        payload: {
            ...langFile,
            code, alias
        }
    });
    showToast( 'success', 'Language added' );
};

export const deleteLanguage = ( code, alias ) => dispatch => {
    dispatch({
        type: 'DELETE_LANG_FILE',
        payload: {
            code, alias
        }
    });
};

export const setPreferredLanguage = ( code, alias ) => ( dispatch, getState ) => {
    const state = getState();
    const { languages } = state.reducer;

    languages.forEach(( lang ) => {
        lang.preferred = false;
    });

    if ( code && alias ) {
        const lang = languages.find(( lng ) => lng.code === code );
        lang.preferred = true;
    }

    const copyLangs = JSON.parse( JSON.stringify( languages ));

    uploadLangFiles( copyLangs, dispatch );
};

export const uploadFile = ( file, type ) => ( dispatch, getState ) => {
    const { reducer } = getState();
    const { base, languages } = reducer;
    if ( type === 'base' ) {
        if ( file && file['base']) {
            uploadBaseFile( file, dispatch );
            if ( languages.length > 0 ){
                updateLangKeys( file['base'], languages, dispatch );
            }
        } else {
            showToast( 'error', 'Base File Uploaded is not in the Correct Format' );
        }
    } else {
        uploadLangFile( file, base, dispatch );
    }
};

const updateLangKeys = ( file, langs, dispatch ) => {
    langs.forEach(( lang ) => {
        const baseLangFile = formLangFile( file );
        compareLangs( baseLangFile, lang );
    });

    const copyLangs = JSON.parse( JSON.stringify( langs ));

    uploadLangFiles( copyLangs, dispatch );
};

const formChildName = ( name, obj, langJson ) => {
    if ( obj['children'] && obj['children'].length ) {
        obj['children'].forEach( childObj => {
            const childName = childObj['name'];
            formChildName( `${name}.${childName}`, childObj, langJson );
        });
    } else {
        langJson[name] = '';
    }
};

const formLangFile = ( base ) => {
    const langJson = {};
    langJson['translations'] = {};

    if ( base && base.length ) {
        base.forEach(( obj ) => {
            const { name } = obj;
            formChildName( name, obj, langJson['translations']);
        });
    }
    return langJson;
};

const updateLangFiles = ( langs, path ) => {
    langs.forEach( lang => {
        const keys = Object.keys( lang['translations']);
        if ( keys.indexOf( path ) === -1 ) {
            lang['translations'][path] = '';
        }
    });

    return JSON.parse( JSON.stringify( langs ));
};

export const returnBaseObj = ( base, path ) => {
    const paths = path.split( '.' );
    const firstPath = paths?.[0];

    let baseObj = base.find(( obj ) => obj.name === firstPath );

    if ( paths.length > 1 ) {
        const subPaths = paths.slice( 1 );
        subPaths.forEach(( key ) => {
            const { children } = baseObj;
            const chObj = children.find(( obj ) => obj.name === key );
            baseObj = chObj;
        });
    }

    return baseObj;

};

const updateBaseFile = ( base, newKey, path ) => {
    const splitPath = path.split( '.' );
    const updatePath = splitPath.slice( 0, splitPath.length - 1 );
    const baseObj = returnBaseObj( base, updatePath.join( '.' ));
    const { children } = baseObj;
    if ( children ) {
        children.push( newKey );
    }
    return JSON.parse( JSON.stringify({ base }));
};

const updateBaseWithDesc = ( base, desc, path ) => {
    const baseObj = returnBaseObj( base, path );
    baseObj.description = desc;
    return JSON.parse( JSON.stringify({ base }));
};

const updateLangFilesWithValues = ( langs, obj, path ) => {
    langs.forEach(( lang ) => {
        const langName = lang['alias'];
        lang['translations'][path] = obj[langName];
    });

    return JSON.parse( JSON.stringify( langs ));
};

const findEmptyPathinLangs = ( languages, path ) => {
    const langs = languages.map( lang => lang['translations']);
    let isEmpty = false;

    langs.forEach( lng => {
        if ( lng[`${[path]}`] === '' || lng[`${[path]}`] === null ) {
            isEmpty = true;
        }
    });

    return isEmpty;
};

const filterBasedonEmptyPath = ( filters, searchRes, searchVal, languages, path ) => {
    if ( filters['isEmptyValue']) {
        const isPathEmpty = findEmptyPathinLangs( languages, path );
        if ( isPathEmpty ) {
            searchRes.push( searchVal );
        }
    } else {
        searchRes.push( searchVal );
    }
};

const filterSearch = ( chObj, searchVal, item, searchRes, filters, languages ) => {
    const path = searchVal.split( ' > ' ).join( '.' );
    const isNameMatch = item !== '*' ? chObj['name']?.toLowerCase()?.includes( item ) : true;
    const isDescMatch = item !== '*' ? chObj['description']?.toLowerCase()?.includes( item ) : true;

    if ( filters['isOnlyKeys']) {
        if ( isNameMatch ) {
            filterBasedonEmptyPath( filters, searchRes, searchVal, languages, path );
        }

    } else if ( filters['isOnlyDesc']) {
        if ( isDescMatch ) {
            filterBasedonEmptyPath( filters, searchRes, searchVal, languages, path );
        }
    } else {
        if ( isNameMatch || isDescMatch ) {
            filterBasedonEmptyPath( filters, searchRes, searchVal, languages, path );
        }
    }

};

const searchItemInChild = ( name, obj, item, searchRes, filters, languages ) => {
    if ( obj.children && obj.children.length > 0 ) {
        for ( const chObj of obj.children ) {
            const searchVal = `${name} > ${chObj['name']}`;
            filterSearch( chObj, searchVal, item, searchRes, filters, languages );
            searchItemInChild( searchVal, chObj, item, searchRes, filters, languages );
        }
    }

};

const searchItemInBase = ( base, searchItem, filters, languages ) => {
    const searchRes = [];

    for ( const obj of base ) {
        const { name } = obj;
        filterSearch( obj, name, searchItem, searchRes, filters, languages );
        searchItemInChild( name, obj, searchItem, searchRes, filters, languages );
    }

    return searchRes;

};

export const saveKey = ( key, path, isFolder ) => ( dispatch, getState ) => {
    const state = getState();
    const { reducer } = state,
        { base, languages } = reducer;

    const newKey = {
        name: `${key}`,
        description: ''
    };

    if ( isFolder ) {
        newKey['nonEditable'] = true;
        newKey['children'] = [];
    }

    const baseFile = updateBaseFile( base, newKey, path );
    uploadBaseFile( baseFile, dispatch );

    if ( !isFolder ) {
        const langFiles = updateLangFiles( languages, path );
        uploadLangFiles( langFiles, dispatch );
    }

    showToast( 'success', 'Key added Successfully' );
};

export const saveBaseKey = ( key ) => ( dispatch, getState ) => {
    const state = getState();
    const { reducer } = state,
        { base } = reducer;

    const baseNames = base.map(( obj ) => obj.name );

    if ( baseNames.indexOf( key ) === -1 ) {
        const newKey = {
            name: `${key}`,
            description: '',
            nonEditable :true,
            children : []
        };

        const newBase = [ ...base, newKey ];

        const baseFile = JSON.parse( JSON.stringify({ base: newBase }));
        uploadBaseFile( baseFile, dispatch );
        showToast( 'success', 'Base Folder Added !!' );
    } else {
        showToast( 'error', 'Duplicate KeyFound! Please try again' );
    }
};

export const saveDescription = ( desc, path ) => ( dispatch, getState ) => {
    const state = getState();
    const { reducer } = state,
        { base } = reducer;

    const baseFile = updateBaseWithDesc( base, desc, path );
    uploadBaseFile( baseFile, dispatch );
    showToast( 'success', 'Description Updated !!' );
};

export const saveTranslations = ( obj, path ) => ( dispatch, getState ) => {
    const state = getState();
    const { reducer } = state,
        { languages } = reducer;

    const langs = updateLangFilesWithValues( languages, obj, path );
    uploadLangFiles( langs, dispatch );
    showToast( 'success', 'Translations saved !!' );
};

export const applyFilters = ( filters ) => ( dispatch ) => {
    dispatch({
        type: 'UPDATE_FILTERS',
        payload: { ...filters }
    });
};

export const searchItemInPath = ( searchItem ) => ( dispatch, getState ) => {
    dispatch({
        type: 'FETCHING_RESULTS',
        payload: {
            fetching: true
        }
    });
    const { base, languages, filters } = getState().reducer;

    const searchRes = searchItemInBase( base, searchItem.toLowerCase(), filters, languages );

    dispatch({
        type: 'SET_SEARCH_RESULTS',
        payload: {
            searchResults: searchRes,
            filtersApplied: filters,
            fetching: false
        }
    });
};

export const clearResults = ( history ) => dispatch => {
    dispatch({
        type: 'CLEAR_SEARCH_RESULTS',
        payload: {
            searchResults: [],
            filtersApplied: {},
            fetching: true
        }
    });
    history.push( '/editor' );
};