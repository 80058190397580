import { getHttp, urlBuilder, extractError } from './axiosUtils';

export const TRANSLATE_SUCCESS = 'TRANSLATE_SUCCESS';
export const TRANSLATE_FAILURE = 'TRANSLATE_FAILURE';
export const TRANSLATE_FETCH = 'TRANSLATE_FETCH';
export const RESET_TRASNSLATE_PROPS = 'RESET_TRASNSLATE_PROPS';

export const translator = ( text, targetLanguage = 'en', sourceLanguage = 'auto' ) => {
    return ( dispatch, state ) => {
        const client = getHttp( dispatch, state );
        const translateAPI = urlBuilder(['amorphic-translator-service'], [`text=${text}&target_language=${targetLanguage}&source_language=${sourceLanguage}`]);
        dispatch({ type: TRANSLATE_FETCH, data: { fetching: true, translatedText: undefined, translateError: undefined } });
        return client.get( translateAPI )
            .then( response => {
                dispatch({
                    type: TRANSLATE_SUCCESS,
                    data: {
                        translatedText: response.data,
                        fetching: undefined
                    }
                });
            })
            .catch( error_obj => {
                dispatch({
                    type: TRANSLATE_FAILURE,
                    data: {
                        translateError: extractError( error_obj ),
                        fetching: undefined
                    }
                });
            });
    };
};

export const resetTranslateProps = ( propsToReset ) => {
    return ( dispatch ) => {
        dispatch({ type: RESET_TRASNSLATE_PROPS, data: propsToReset });
    };
};