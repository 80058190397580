
export const toggleNotif = ( obj ) => dispatch => {
    dispatch({
        type: 'TOGGLE_NOTIFICATION',
        payload: {
            isError: obj.isError,
            show: obj.show,
            message: obj.message
        }
    });
};

export const resetNotif = () => dispatch => {
    dispatch({
        type: 'RESET_NOTIFICATION'
    });
};

export const showErrorNotif = ( msg ) => dispatch => {
    dispatch( toggleNotif({
        isError: true,
        show:true,
        msg
    }));
};

export const showSuccessNotif = ( msg ) => dispatch => {
    dispatch( toggleNotif({
        isError: false,
        show:true,
        msg
    }));
};