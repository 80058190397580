import AWS from 'aws-sdk/global';
import Swal from 'sweetalert2';
// import { CognitoAuth } from 'amazon-cognito-auth-js';

import config from '../../config/config.json';
import { USER_LOGOUT } from './commonActions';
import { findDuration, toMoment } from '../../components/utils/dateUtils';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const RESET_AUTH_PROPS = 'RESET_AUTH_PROPS';
const TOKEN_REFRESH_SUCCESS = 'TOKEN_REFRESH_SUCCESS';
const HEARTBEAT = 'HEARTBEAT';

const JWT = require( 'jsonwebtoken' );
const AmazonCognitoIdentity = require( 'amazon-cognito-identity-js' );

const AWS_REGION = config.region;
const COGNITO_USER_POOL_ID = config.userPool;
const COGNITO_CLIENT_ID = config.clientId;
const COGNITO_IDENTITY_POOL_ID = config.identityPool;
const DOMAIN = `cognito-idp.${ AWS_REGION }.amazonaws.com/${ COGNITO_USER_POOL_ID }`;
const USER_POOL = new AmazonCognitoIdentity.CognitoUserPool({
    UserPoolId: COGNITO_USER_POOL_ID,
    ClientId: COGNITO_CLIENT_ID
});

AWS.config.region = AWS_REGION;

let currentUser = undefined;
const getCognitoUser = ( username ) => {
    return typeof currentUser !== 'undefined' ? currentUser : new AmazonCognitoIdentity.CognitoUser({
        Username : username,
        Pool : USER_POOL
    });
};

const loginDispatch = ( dispatch, history, user, password ) => {
    const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
        Username : user,
        Password : password
    });

    const cognitoUser = getCognitoUser( user );

    cognitoUser.authenticateUser( authenticationDetails, {
        onSuccess ( result ) {
            const decoded = JWT.decode( result.getIdToken().getJwtToken(), { complete: true });
            const email = decoded.payload['email'] ? decoded.payload['email'] : undefined;
            const username = decoded.payload['cognito:username']
                ? decoded.payload['cognito:username']
                : email
                    ? email.substring( 0, email.lastIndexOf( '@' ))
                    : undefined;
            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                IdentityPoolId: COGNITO_IDENTITY_POOL_ID,
                Logins : {}
            });
            AWS.config.credentials.params.Logins[DOMAIN] = result.getIdToken().getJwtToken();
            AWS.config.credentials.refresh(( error ) => {
                if ( error ) {
                    console.error( error );
                } else {
                    // eslint-disable-next-line
                    console.info( 'Successfully logged in!' );
                }
            });
            dispatch({
                type: LOGIN_SUCCESS,
                data: {
                    token: result.getIdToken().getJwtToken(),
                    refreshToken: result.getRefreshToken().getToken(),
                    loginTime: toMoment( null, 'epoch' ),
                    lastActivity: toMoment( null, 'epoch' ),
                    email,
                    userName: username
                }
            });
            currentUser = cognitoUser;
            history.push( '/home' );
        },

        onFailure( err ) {
            dispatch({
                type: LOGIN_FAILURE,
                data: {
                    loginError: err.message || JSON.stringify( err ),
                    loginProgress: false
                }
            });
        },

        totpRequired( _secretCode ) {
            getTotp()
                .then( challengeAnswer => {
                    cognitoUser.sendMFACode( challengeAnswer, this, 'SOFTWARE_TOKEN_MFA' );
                });
        },

        newPasswordRequired( userAttributes, _requiredAttributes ) {
            delete userAttributes.email_verified;
            delete userAttributes.email;
            Swal.fire({
                type: 'error',
                text: 'Password reset required'
            });
        }
    });
};

const getTotp = async ( error ) => {
    const { value: totpCode } = await Swal.fire({
        title: 'Enter your MFA Code',
        text: error || '',
        input: 'text',
        inputValue: '',
        showCancelButton: true,
        confirmButtonText: 'Verify MFA',
        reverseButtons: true,
        focusConfirm: false,
        inputAttributes: {
            maxLength: 10,
            id: '2FA'
        },
        inputValidator: ( value ) => {
            if ( !value ) {
                return 'Please enter MFA Code';
            }
        }
    });
    return totpCode;
};

export const logout = ( history ) => {
    return ( dispatch, _getState ) => {
        const loginPath = '/';

        Swal.close();
        try {
            const cognitoUser = USER_POOL.getCurrentUser();
            if ( cognitoUser !== null ){
                cognitoUser.signOut();
            }
        } catch ( error ) {
            console.error( error );
            dispatch({ type: USER_LOGOUT });
        } finally {
            dispatch({ type: USER_LOGOUT });
            if ( typeof history !== 'undefined' ){
                history.push( loginPath );
            } else {
                window.location.replace( loginPath );
            }
        }

    };
};

export const login = ( history, user, password ) => {
    return ( dispatch, _getState ) => {
        loginDispatch( dispatch, history, user, password );
    };
};

export const keepAlive = ( loginTime, refreshToken, userName, forceReset = false, retry_count = 0 ) => {
    return ( dispatch ) => {
        // refresh token for every 30 minutes
        if ( forceReset || ( findDuration( toMoment( null, 'epoch' ), toMoment( loginTime, 'epoch' )).toFixed( 2 ) > 29 )) {
            const cognitoUser = getCognitoUser( userName );
            const RefreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({ RefreshToken: refreshToken });
            cognitoUser.refreshSession( RefreshToken, ( err, session ) => {
                if ( err ) {
                    if ( retry_count < 3 ){
                        Swal.fire({
                            title: 'Session Error !',
                            html: `Error continuing session.<br>${err?.message || ''}`,
                            type: 'warning',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            showCancelButton: true,
                            reverseButtons: true,
                            cancelButtonText: 'Sign out',
                            confirmButtonText: 'Reload session',
                            backdrop: 'rgba(102, 102, 102, 0.32)'
                        }).then(( result ) => {
                            if ( result.value ) {
                                dispatch( keepAlive( loginTime, refreshToken, userName, true, retry_count + 1 ));
                            } else if ( result.dismiss === Swal.DismissReason.cancel ){
                                dispatch( logout());
                            }
                        });
                    } else {
                        // user tried to refresh session more than 3 times, possibly refreshToken is Expired
                        // at this point, only course of action is to logout
                        Swal.fire({
                            title: 'Session expired',
                            html: `Error continuing session.<br>${err?.message || ''}`,
                            type: 'error',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            confirmButtonText: 'Logout',
                            backdrop: 'rgba(102, 102, 102, 0.8)',
                            onClose: () => {
                                dispatch({ type: USER_LOGOUT });
                                dispatch( logout());
                            }
                        });
                    }
                } else {
                    // eslint-disable-next-line no-console
                    Swal.close();
                    dispatch({
                        type: TOKEN_REFRESH_SUCCESS,
                        data: {
                            token: session.idToken.jwtToken,
                            refreshToken: session.refreshToken.token,
                            loginTime: toMoment( null, 'epoch' ),
                            lastActivity: toMoment( null, 'epoch' )
                        }
                    });
                }
            });
        } else {
            dispatch({
                type: HEARTBEAT,
                data: {
                    lastActivity: toMoment( null, 'epoch' )
                }
            });
        }
    };
};

export const resetAuthProps = ( propsToReset ) => {
    return ( dispatch ) => {
        dispatch({ type: RESET_AUTH_PROPS, data: propsToReset });
    };
};