import React from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
    faPlus, faFileUpload, faFileExport,
    faPlusCircle, faEdit, faFolderOpen,
    faFolder, faKey, faFileImport,
    faPowerOff, faSignInAlt, faUser,
    faLanguage, faSync, faAngleDoubleDown,
    faCopy, faPen, faSave, faFolderPlus,
    faFileMedical, faFileDownload, faTrash,
    faEye, faTimes, faSearch, faStar, faFilter
} from '@fortawesome/free-solid-svg-icons';

library.add(
    faPlus, faFileUpload, faFileExport,
    faPlusCircle, faEdit, faFolderOpen,
    faFolder, faKey, faFileImport,
    faPowerOff, faSignInAlt, faUser,
    faLanguage, faSync, faAngleDoubleDown,
    faCopy, faPen, faSave, faFolderPlus,
    faFileMedical, faFileDownload, faTrash,
    faEye, faTimes, faSearch, faStar, faFilter
);

const ATMIcon = ({
    icon,
    ...props
}) => (
    <FontAwesomeIcon icon={ icon } fixedWidth {...props} />
);

export default ATMIcon;