import React, { useState } from 'react';
import { Navbar, Nav, Form,
    FormControl, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import ATMIcon from '../utils/ATMIcon';
import HeaderLinks from './HeaderLinks';
import { WithHelperText } from '../utils/helperFns';

const Header = ({
    user = {},
    filters = {},
    toggleFilter = () => {},
    logout
}) => {
    const [ searchItem, setSearchItem ] = useState( '' );
    const history = useHistory();

    const searchButton = ( e ) => {
        e.stopPropagation();
        history.push( `/editor/${searchItem}` );
        setSearchItem( '' );
    };

    const getFiltersLength = () => {
        if ( filters !== {}) {
            const filterKeys = Object.keys( filters );
            const filtersAdded = filterKeys.filter( key => filters[`${key}`]);
            return filtersAdded && filtersAdded.length > 0 ? `(${filtersAdded.length})` : '';
        }

        return '';
    };

    return <Navbar bg="dark" expand="lg">
        <Navbar.Brand href="/home">
            <ATMIcon icon="language" className="d-inline-block align-top text-primary" size="2x" />
            { ' ' }
            <span className="text-white projectTitle">Amorphic Translation Manager</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
            { user?.token && <>
                <Form inline onSubmit={( e ) => searchButton( e )}>
                    <FormControl type="text" placeholder="Search" value={searchItem} className="mr-sm-4" onChange={e => setSearchItem( e.target.value )} />
                    <Button className="btn btn-info" disabled={!searchItem} type="submit"><ATMIcon icon="search" /></Button>
                </Form>
                <Button className="btn btn-info ml-1"
                    onClick={() => toggleFilter()}
                >
                    <ATMIcon icon="filter" /><span>{getFiltersLength()}</span>
                </Button>

                <Nav className="nav-z-index ml-auto">
                    <HeaderLinks />
                    <Button className="app-btn btn btn-sm btn-danger" onClick={ () => logout() }>
                        <WithHelperText tooltip="Logout">
                            <ATMIcon icon="power-off" />
                        </WithHelperText>
                    </Button>
                </Nav>
            </> }
        </Navbar.Collapse>
    </Navbar>;
};

export default Header;