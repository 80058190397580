import React from 'react';
import { ListGroup, Row, Col,
    Alert, Jumbotron } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import ATMIcon from './utils/ATMIcon';
import Notification from './utils/Notification';
import { WithHelperText } from './utils/helperFns';

const Home = ({
    base,
    languages,
    deleteLanguage = () => {},
    setPreferredLanguage = () => {}
}) => {
    return <Row className="mt-3">
        <Col md={{ span:10, offset:1 }}>
            { base && base.length > 0
                ? <React.Fragment>
                    <Alert variant="success">Base file loaded !!</Alert>
                    { languages?.length > 0
                        ? <React.Fragment>
                            <h4>Languages</h4>
                            <ListGroup>
                                {languages.map(( lang, index ) => {
                                    return <ListGroup.Item key={lang + index}>
                                        {lang.alias}
                                        <WithHelperText tooltip="remove language">
                                            <button className="btn btn-danger btn-xs float-right"
                                                onClick={() => deleteLanguage( lang.code, lang.alias )}
                                            >
                                                <ATMIcon icon="trash" />
                                            </button>
                                        </WithHelperText>
                                        { lang.preferred
                                            ? <WithHelperText tooltip="Remove preferred language">
                                                <button className={'btn btn-success btn-xs float-right'}
                                                    onClick={() => setPreferredLanguage()}
                                                >
                                                    <ATMIcon icon="star" />
                                                </button>
                                            </WithHelperText>
                                            : <WithHelperText tooltip="Set preferred language">
                                                <button className={'btn btn-secondary btn-xs float-right'}
                                                    onClick={() => setPreferredLanguage( lang.code, lang.alias )}
                                                >
                                                    <ATMIcon icon="star" />
                                                </button>
                                            </WithHelperText>
                                        }
                                    </ListGroup.Item>;
                                })}
                            </ListGroup>
                            <div className="clearfix"></div><br/>
                            <LinkContainer to={'/editor'}>
                                <button className="btn btn-primary float-right"><ATMIcon icon="edit" /> Update Translations</button>
                            </LinkContainer>
                        </React.Fragment>
                        : <Jumbotron>
                            <h3>Languages not found</h3>
                            <p>Please upload language file(s) or create a new one.</p>
                        </Jumbotron>
                    }
                </React.Fragment>
                : <Jumbotron>
                    <h3>Base file not found</h3>
                    <p>Please upload base file first.</p>
                </Jumbotron>
            }
        </Col>
        <Notification />
    </Row>;
};

export default Home;