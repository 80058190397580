import authReducer from './authReducer';
import baseReducer from './baseReducer';
import notifReducer from './notifReducer';
import translateReducer from './translateReducer';

export default {
    auth: authReducer,
    reducer:  baseReducer,
    notif: notifReducer,
    translate: translateReducer
};