import axiosRetry from 'axios-retry';
import { keepAlive } from './authActions';

import configuration from '../../config/config.json';

const axios = require( 'axios' );
const enAPI = configuration.API_gateway;

export const getHttp = ( dispatch, state ) => {
    // Refresh Cognito session
    dispatch( keepAlive( state().auth.loginTime, state().auth.refreshToken, state().auth.userName ));

    const client = axios.create();
    // timeout axios requests to APIGW after 90secs
    client.defaults.timeout = 90000;
    client.defaults.headers.common['Authorization'] = state().auth.token;

    axiosRetry( axios, { retries: 2, retryDelay: axiosRetry.exponentialDelay });
    return client;
};

export const urlBuilder = ( urlTerms = [], parameters = []) => {
    return enAPI.concat( urlTerms.join( '/' )).concat( parameters.length > 0 ? `?${parameters.join( '&' )}` : '' );
};

export const extractMessage = ( data, fallback = '' ) => {
    try {
        return data?.Message || data?.message || fallback;
    } catch ( e ) {
        return fallback;
    }
};

export const extractError = ( error_obj, fallbackError = 'Network Error' ) => {
    try {
        return extractMessage( error_obj?.response?.data, error_obj?.data ) || extractMessage( error_obj, fallbackError );
    } catch ( e ) {
        return fallbackError;
    }
};
