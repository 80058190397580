import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Toast, Row, Col } from 'react-bootstrap';
import { resetNotif } from '../../modules/actions/notifActions';
import '../../styles/Notification.css';

const Notification = ( _props ) => {
    const { isError, show, message } = useSelector(( state ) => ({
        isError: state.notif.isError,
        show: state.notif.show,
        message: state.notif.message
    }));

    const dispatch = useDispatch();

    return (
        <Row>
            <Col className="notification-main">
                <Toast className={isError ? 'notification-error' : 'notification-success'} show={show} onClose={() => dispatch( resetNotif())}>
                    <Toast.Header>
                        <strong className="mr-auto">Notification</strong>
                    </Toast.Header>
                    <Toast.Body className="notification-text" >{message}</Toast.Body>
                </Toast>
            </Col>
        </Row>
    );

};

export default Notification;