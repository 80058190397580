import React from 'react';
import { Collapse, Form, Button, Row, Col } from 'react-bootstrap';
import { applyFilters } from '../modules/actions/actions';
import { connect } from 'react-redux';

class Filters extends React.Component {
    constructor( props ){
        super( props );
        this.state = {
            isEmptyValue: false,
            isOnlyKeys: false,
            isOnlyDesc: false
        };

    }

    componentDidMount = () => {
        const { filters } = this.props;
        this.setState({
            isEmptyValue: filters?.isEmptyValue,
            isOnlyKeys: filters?.isOnlyKeys,
            isOnlyDesc: filters?.isOnlyDesc
        });
    }

    onChangeFilter = ( key ) => {

        switch ( key ) {
        case 'isEmptyValue':
            this.setState({
                isEmptyValue: !this.state.isEmptyValue
            });
            break;
        case 'isOnlyKeys':
            this.setState({
                isOnlyKeys: !this.state.isOnlyKeys,
                isOnlyDesc: false
            });
            break;
        case 'isOnlyDesc':
            this.setState({
                isOnlyDesc: !this.state.isOnlyDesc,
                isOnlyKeys: false
            });
            break;
        default:
            break;
        }

    }

    applyNewFilters = () => {
        this.props.applyFilters( this.state );
        this.props.toggleFilter();
    }

    clearFilters = () => {
        this.setState({
            isEmptyValue: false,
            isOnlyKeys: false,
            isOnlyDesc: false
        }, () => {
            this.props.applyFilters( this.state );
            this.props.toggleFilter();
        });
    }

    render() {
        return (

            <Collapse in={this.props.isFilterOpen}>
                <Form inline as={Row} className="filter-section">
                    <Col>
                        <Form.Group controlId="formGroupEmptyValue">
                            <Form.Check type="checkbox" label="Search For Empty Values" checked={this.state.isEmptyValue} onChange={() => this.onChangeFilter( 'isEmptyValue' )}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formGroupOnlyKeys">
                            <Form.Check type="checkbox" label="Search in Only Keys" checked={this.state.isOnlyKeys} onChange={() => this.onChangeFilter( 'isOnlyKeys' )}/>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId="formGroupOnlyDesc">
                            <Form.Check type="checkbox" label="Search in Only Description" checked={this.state.isOnlyDesc} onChange={() => this.onChangeFilter( 'isOnlyDesc' )} />
                        </Form.Group>

                    </Col>
                    <Col>
                        <span className="hint-class"> Hint: Use &apos;*&apos; to retrieve all results </span>
                    </Col>

                    <Col sm={5} md={2}>
                        <span className="click-link" onClick={() => this.clearFilters()}>Clear Filters</span>
                        <Button className="btn btn-sm float-right" onClick={() => this.applyNewFilters()} >Apply Filters</Button>
                    </Col>
                </Form>
            </Collapse>
        );
    }
}

const mapStateToProps = ( state ) => {
    const { filters } = state.reducer;
    return {
        filters
    };
};

const mapDispatchToProps = ( dispatch, _props ) => ({
    applyFilters: ( state ) => dispatch( applyFilters( state ))
});

export default connect( mapStateToProps, mapDispatchToProps )( Filters );