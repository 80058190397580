/*
 src/reducers/baseReducer.js
*/
import { USER_LOGOUT } from '../actions/commonActions';

const initialState = {
    base: [],
    languages: [],
    searchTerm: '',
    searchResults: [],
    filters: {
        isEmptyValue: false,
        isOnlyKey: false,
        isOnlyDesc: false
    },
    fetching: true
};

export default ( state = initialState, action ) => {
    const newState = { ...state };
    switch ( action.type ) {
    case 'ADD_BASE_FILE':
        newState.base = action.payload.base;
        return newState;
    case 'ADD_LANG_FILE':
        const existingLanguage = state.languages.some( l => l.code === action.payload.code );
        const langCode = action.payload['code'];
        const langs = [...newState.languages];

        if ( existingLanguage ) {
            const langObj = langs.find(( obj ) => obj['code'] === langCode );
            langObj['translations'] = action.payload['translations'];
        } else {
            langs.push( action.payload );
        }
        newState.languages = langs;
        return newState;
    case 'DELETE_LANG_FILE':
        const newLangList = [...newState.languages].filter( lang => ( lang.code !== action.payload.code && lang.alias !== action.payload.alias ));
        newState.languages = newLangList;
        return newState;
    case 'UPDATE_LANG_FILES':
        newState.languages = [...action.payload];
        return newState;
    case 'UPDATE_FILTERS':
        newState.filters = { ...action.payload };
        return newState;
    case 'FETCHING_RESULTS':
    case 'SET_SEARCH_RESULTS':
    case 'CLEAR_SEARCH_RESULTS':
        return { ...newState, ...action.payload };
    case USER_LOGOUT:
        return initialState;
    default:
        return state;
    }
};

