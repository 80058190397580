import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, ListGroup, ListGroupItem } from 'react-bootstrap';
import ModalPopup from '../../common/ModalPopup';
import ATMIcon from '../utils/ATMIcon';
import { WithHelperText } from '../utils/helperFns';

class Exporter extends React.Component {

    constructor( props ) {
        super( props );
        this.state = {
            file: null
        };
    }

    closeModal = () => {
        this.setState({ file : null });
    }

    downloadTranslation = ( language ) => {
        const element = document.createElement( 'a' );
        const file = new Blob([JSON.stringify( language, null, 2 )], { type : 'application/json' });
        element.href = URL.createObjectURL( file );
        element.download = `${language?.code || 'base'}.json`;
        document.body.appendChild( element );
        element.click();
    }

    downloadAll = () => {
        const { base, languages } = this.props;

        this.downloadTranslation({ base });

        languages.forEach(( language ) => {
            this.downloadTranslation( language );
        });
    }

    render() {
        const { base, languages } = this.props;
        const { file } = this.state;

        return (
            <>
                <ModalPopup
                    size="lg"
                    noHeader
                    noClass
                    onHide={() => this.closeModal()}
                    show={!!file}>
                    <span className="title">{ file?.alias || 'Base' }&nbsp;</span> { file?.code && <small>( { file?.code } )</small>}
                    <WithHelperText tooltip="Download File">
                        <button className="btn btn-success btn-xs float-right">
                            <ATMIcon icon="file-download" onClick={() => this.downloadTranslation( file )}/>
                        </button>
                    </WithHelperText>
                    <div className="fixed-container">
                        <pre className="code">{ JSON.stringify( file, null, 2 ) }</pre>
                    </div>
                </ModalPopup>

                <ListGroup className="m-5">
                    <div>
                        <button className="btn btn-success btn-sm float-right mb-1" onClick={() => this.downloadAll()}>
                        Download All
                        </button>
                    </div>
                    <ListGroupItem variant="dark" >
                        <Row>
                            <Col>
                                <span className="title">  Base </span>
                            </Col>
                            <Col>
                                <div className="float-right">
                                    <WithHelperText tooltip="Preview Base File">
                                        <button className="btn btn-info btn-xs">
                                            <ATMIcon icon="eye" onClick={() => this.setState({ file: { base } })}/>
                                        </button>
                                    </WithHelperText>
                                    <WithHelperText tooltip="Download Base File">
                                        <button className="btn btn-success btn-xs">
                                            <ATMIcon icon="file-download" onClick={() => this.downloadTranslation({ base })}/>
                                        </button>
                                    </WithHelperText>
                                </div>
                            </Col>
                        </Row>
                    </ListGroupItem>
                </ListGroup>

                <ListGroup className="m-5">
                    {languages.map(( language, lIndex ) =>
                        <ListGroupItem key={lIndex} variant="light" >
                            <Row>
                                <Col>
                                    <span className="title">{ language.alias }&nbsp;</span><small>( { language.code } )</small>
                                </Col>
                                <Col>
                                    <div className="float-right">
                                        <WithHelperText tooltip="Preview Language">
                                            <button className="btn btn-info btn-xs">
                                                <ATMIcon icon="eye" onClick={() => this.setState({ file: language })}/>
                                            </button>
                                        </WithHelperText>
                                        <WithHelperText tooltip="Download Language">
                                            <button className="btn btn-success btn-xs">
                                                <ATMIcon icon="file-download" onClick={() => this.downloadTranslation( language )}/>
                                            </button>
                                        </WithHelperText>
                                    </div>
                                </Col>
                            </Row>
                        </ListGroupItem> )}
                </ListGroup>
            </>
        );
    }

}

const mapStateToProps = state => {
    const { base, languages } = state.reducer;
    return {
        base,
        languages
    };
};

export default connect( mapStateToProps, null )( Exporter );