
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ModalPopup = ({
    label,
    nofooter = false,
    noHeader = false,
    noClass = false,
    onHide,
    size,
    children,
    ...props
}) => {
    return (
        <Modal
            {...props}
            size={size || 'lg'}
            centered
            onHide={onHide}
            className={!noClass ? 'modal-top-aligned' : '' }
        >
            { !noHeader && label &&
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {label}
                </Modal.Title>
            </Modal.Header>}
            <Modal.Body>
                {children}
            </Modal.Body>
            { !nofooter && <Modal.Footer>
                <Button variant="secondary" onClick={() => onHide()}>Close</Button>
            </Modal.Footer>
            }
        </Modal>
    );
};

export default ModalPopup;