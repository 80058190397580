import { USER_LOGOUT } from '../actions/commonActions';
import {
    TRANSLATE_FETCH,
    TRANSLATE_SUCCESS,
    TRANSLATE_FAILURE,
    RESET_TRASNSLATE_PROPS
} from '../actions/translateActions';

const initialState = {};

export default ( state = initialState, action ) => {
    switch ( action.type ) {
    case TRANSLATE_FETCH:
    case TRANSLATE_SUCCESS:
    case TRANSLATE_FAILURE:
    case RESET_TRASNSLATE_PROPS:
        return { ...state, ...action.data };
    case USER_LOGOUT:
        return initialState;
    default:
        return state;
    }
};