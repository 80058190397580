import React, { useState } from 'react';
import { Row, Col, Form, InputGroup } from 'react-bootstrap';

import ATMIcon from '../utils/ATMIcon';

const Login = ({
    login,
    loginError
}) => {
    const [ username, setUsername ] = useState( '' );
    const [ password, setPassword ] = useState( '' );
    const [ usernameError, setUsernameError ] = useState();
    const [ passwordError, setPasswordError ] = useState();

    const initiateLogin = () => {
        if ( username?.length > 0 && password?.length > 0 ){
            login( username, password );
        } else {
            if ( !username || username?.length === 0 ) {
                setUsernameError( 'Username required.' );
            }
            if ( !password || password?.length === 0 ) {
                setPasswordError( 'Password required.' );
            }
        }
    };

    return <div className="full-height">
        <div className="login-container">
            <Row>
                <Col sm={12} className="text-danger">{ loginError }</Col>
                <Col sm={12}>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label className="d-none d-xs-block">Username</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroupPrepend"><ATMIcon icon="user" /></InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control type="text" placeholder="Username" value={ username } onChange={ ( e ) => setUsername( e?.target?.value ) } />
                            { usernameError && <Form.Control.Feedback type="invalid">{ usernameError }</Form.Control.Feedback> }
                        </InputGroup>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>
                    <Form.Group controlId="formBasicPassword">
                        <Form.Label className="d-none d-xs-block">Password</Form.Label>
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroupPrepend"><ATMIcon icon="key" /></InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control type="password" placeholder="Password" value={ password } onChange={ ( e ) => setPassword( e?.target?.value ) } />
                            { passwordError && <Form.Control.Feedback type="invalid">{ passwordError }</Form.Control.Feedback> }
                        </InputGroup>
                    </Form.Group>
                </Col>
            </Row>
            <div className="clearfix"></div><br/>
            <Row>
                <Col sm={12}>
                    <button type="submit" className="btn btn-success btn-black float-right" onClick={() => initiateLogin()}><ATMIcon icon="sign-in-alt" /> Login</button>
                </Col>
            </Row>
        </div>
    </div>;
};

export default Login;