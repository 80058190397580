import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Container, Row, Col, Button, ListGroup,
    Jumbotron, Breadcrumb, Form, Alert, Spinner, Badge } from 'react-bootstrap';

import KeysList from './KeysList';
import ModalPopup from '../../common/ModalPopup';
import Notification from '../utils/Notification';
import UpdateTranslations from './UpdateTranslations';
import { saveKey, saveTranslations, saveBaseKey,
    saveDescription, returnBaseObj } from '../../modules/actions/actions';
import { searchItemInPath, clearResults } from '../../modules/actions/actions';

class Editor extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            showTrans: false,
            obj: null,
            showKey: false,
            path: '',
            showFolder: false,
            isBaseOpen: true,
            key: ''
        };

        this.inputRef = React.createRef();
    }

    componentDidMount = () => {
        const { searchTerm } = this.props?.match?.params;
        if ( searchTerm?.length > 0 ) {
            this.props.search( searchTerm );
        }
    }

    updateTrans = () => {
        this.setState({
            showTrans: !this.state.showTrans
        });
    }

    itemClicked = ( obj, path ) => {
        this.setState({
            obj, path
        });
        this.props.clearResults();
    }

    addNewKey = ( e ) => {
        this.setState({
            key: e.target.value
        });
    }

    saveNewKey = ( isFolder ) => {
        const { key, path, isBaseOpen } = this.state;
        const newPath = `${path}.${key}`;
        this.closeModal();

        if ( !isBaseOpen ) {
            this.props.saveBaseFolder( key );
        } else {
            this.props.saveKey( key, newPath, isFolder );
        }
    }

    saveTrans = ( state ) => {
        const { path } = this.state;
        this.props.saveTranslations( state, path );
    }

    openTranslations = ( res ) => {
        const { base } = this.props;

        const splitRes = res.split( ' > ' );
        const path = splitRes.join( '.' );
        const obj = returnBaseObj( base, path );

        this.setState({
            obj, path
        });

        this.props.clearResults();
    }

    toggleKey = ( isFolder = false ) => {
        if ( isFolder ) {
            this.setState(( preState ) => ({
                key: '',
                showFolder: !preState.showFolder,
                showKey: false
            }));
        } else {
            this.setState(( preState ) => ({
                key: '',
                showKey: !preState.showKey,
                showFolder: false
            }));
        }

        setTimeout(() => {
            const node = this.inputRef?.current;
            node.focus();
        }, 200 );

    }

    closeModal = () => {
        this.setState({
            key: '',
            showFolder: false,
            showKey: false
        });
    }

    toggleBaseOpen = () => {
        this.setState( prevState => ({
            isBaseOpen: !prevState.isBaseOpen
        }));

        this.itemClicked( null, '' );
    }

    render() {
        const { base, languages, searchTerm, searchResults, fetching, filtersApplied } = this.props;
        const { path,
            showKey,
            key,
            obj,
            isBaseOpen,
            showFolder } = this.state;
        const selChain = path?.split( '.' );
        const labelComp = selChain?.length > 0 && <Breadcrumb className="key-header" >
            {selChain.map(( p, index ) => <Breadcrumb.Item key={`${p}_${index}`} active={ selChain.length - 1 === index}>{p}{selChain.length - 1 === index && ' >'}</Breadcrumb.Item> )}
        </Breadcrumb>;

        return ( <>
            { ( !base?.length > 0 || !languages.length > 0 ) && <Redirect to={'/home'} /> }
            <Container fluid className="mt-3">
                <Row>
                    <Col sm={4} md={3} lg={3}>
                        <KeysList
                            obj={obj}
                            path={path}
                            isBaseOpen={isBaseOpen}
                            addKey={( isFolder ) => this.toggleKey( isFolder )}
                            toggleBaseOpen={() => this.toggleBaseOpen()}
                            itemClicked={( expandObj, expandPath ) => this.itemClicked( expandObj, expandPath )}/>
                    </Col>
                    <Col>
                        { searchTerm && searchResults
                            ? <Row className="mt-2 fixed-container">
                                <Col sm={12}>
                                    <Alert variant="secondary">
                                                Search results for <span className="text-primary text-bold">&quot;{searchTerm}&quot; ({ searchResults?.length || 0 })</span>
                                        <span className="click-link float-right" onClick={() => this.props.clearResults()}>Clear Results</span>
                                    </Alert>
                                    { ( filtersApplied?.isEmptyValue || filtersApplied?.isOnlyKeys || filtersApplied?.isOnlyDesc ) && <div>
                                        <Badge variant="primary h6">Filters Applied:</Badge>{ ' ' }
                                        { filtersApplied?.isEmptyValue && <Badge pill variant="success">Search for empty values</Badge> }{ ' ' }
                                        { filtersApplied?.isOnlyKeys && <Badge pill variant="success">Search in keys</Badge> }{ ' ' }
                                        { filtersApplied?.isOnlyDesc && <Badge pill variant="success">Search in description</Badge> }{ ' ' }
                                        <div className="clearfix"></div>
                                    </div>
                                    }

                                    { fetching ? <Spinner animation="border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </Spinner> : ( searchResults.length > 0 ? <ListGroup>
                                        {searchResults.map(( res, index ) => {
                                            return <ListGroup.Item action variant="light" className="text-bold text-info" onClick={() => this.openTranslations( res )} key={index}> {res}  </ListGroup.Item>;
                                        })}
                                    </ListGroup> : <Alert variant="danger">No results found !!</Alert>
                                    )}
                                </Col>
                            </Row> : ( obj ?
                                <UpdateTranslations
                                    path={path}
                                    selChain={selChain}
                                    obj={obj}
                                    labelComp={labelComp}
                                    saveDescription= {( desc ) => this.props.saveDescription( desc, path )}
                                    saveTrans={( state ) => this.saveTrans( state )}
                                />
                                : <Jumbotron>
                                    {isBaseOpen ?
                                        <>
                                            <h3>Select a translation key to update</h3>
                                            <p>Please select a translation key from the list on the left to update.</p>
                                        </>
                                        :
                                        <>
                                            <h3>Click on the folder to select/modify translation keys</h3>
                                            <p>Please click on add base folder on the left to add keys to base</p>
                                        </>
                                    }
                                </Jumbotron> )
                        }
                    </Col>
                </Row>
            </Container>
            <Notification />
            <ModalPopup
                size="lg"
                nofooter
                label={isBaseOpen ? labelComp : 'Add Base Folder'}
                onHide={() => this.closeModal()}
                show={showKey || showFolder}>
                <Row>
                    <Col sm={12} md={{ span:10, offset:1 }}>
                        <Form.Group controlId="add-input">
                            <Form.Label>{showKey ? 'Key' : 'Folder'} Name</Form.Label>
                            <Form.Control type="text" ref={this.inputRef} placeholder={`Enter ${showKey ? 'key' : 'folder'} name`} value={key} onChange={( e ) => this.addNewKey( e )} />
                        </Form.Group>
                    </Col>
                    <div className="clearfix"></div>
                    <br/><br/>
                    <Col sm={12}>
                        <Button className="app-btn btn-sm btn-success float-right" onClick={() => this.saveNewKey( showFolder ) } disabled={!key}> Save</Button>
                    </Col>
                </Row>
            </ModalPopup>
        </>
        );
    }

}

const mapStateToProps = ( state, props ) => {
    const { base, languages, searchResults, fetching, filtersApplied } = state.reducer;
    const { searchTerm } = props.match.params;
    return {
        base,
        languages,
        searchTerm,
        searchResults,
        fetching,
        filtersApplied
    };
};

const mapDispatchToProps = ( dispatch, _props ) => ({
    saveKey: ( key, path, isFolder ) => dispatch( saveKey( key, path, isFolder )),
    search: ( searchTerm ) => dispatch( searchItemInPath( searchTerm )),
    clearResults: () => dispatch( clearResults( _props.history )),
    saveTranslations: ( state, path ) => dispatch( saveTranslations( state, path )),
    saveBaseFolder: ( key ) => dispatch( saveBaseKey( key )),
    saveDescription: ( desc, path ) => dispatch( saveDescription( desc, path ))
});

export default connect( mapStateToProps, mapDispatchToProps )( Editor );

