
import React from 'react';
import { connect } from 'react-redux';
import { Button, Row, Col, Form } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import FileUploadButton from '../utils/FileUploadButton';
import ModalPopup from '../../common/ModalPopup';

import ATMIcon from '../utils/ATMIcon';
import { WithHelperText } from '../utils/helperFns';
import { toggleNotif } from '../../modules/actions/notifActions';
import { uploadFile, addLangFile } from '../../modules/actions/actions';

import languagesList from '../../config/languages.json';

const supportedLanguages = languagesList.languages.filter( l => l.amorphicSupport === true );

class HeaderLinks extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            languageCode: '',
            showAddLang: false
        };
    }

    uploadFileType = ( obj, type ) => {
        this.props.uploadFile( obj, type );
    }

    toggleShow = ( message ) => {
        this.props.showNotif({
            isError: true,
            show:true,
            message
        });
    }

    toggleLang = () => {
        this.setState(( oldState ) => ({
            languageCode: '',
            showAddLang: !oldState.showAddLang
        }));
    }

    addNewLang = () => {
        const { languageCode } = this.state;
        const languageExists = this.props.languages.some( l => l.code === languageCode );
        const languageObj = supportedLanguages.filter( l => l.code === languageCode )?.[0];

        if ( languageExists ) {
            this.toggleShow( 'This Language already exists' );
        } else {
            this.props.addLangFile( languageObj.alias, languageObj.code, this.props.base );
        }
        this.toggleLang();
    }

    render() {
        const { base, languages } = this.props;
        const isBaseNotAvail = !base?.length > 0;
        const langsList = languages.map( l => l.code );
        return (
            <>
                <FileUploadButton
                    type="base"
                    isMultiple={false}
                    icon="file-upload"
                    text={!isBaseNotAvail ? 'Replace Base File' : 'Upload Base File'}
                    uploadFileType={( obj, type ) => this.uploadFileType( obj, type )}/>
                <FileUploadButton
                    type="lang"
                    icon="file-import"
                    isMultiple={true}
                    text="Import Language"
                    isDisabled={isBaseNotAvail}
                    uploadFileType={( obj, type ) => this.uploadFileType( obj, type )}/>
                <WithHelperText tooltip="Add language">
                    <button className="app-btn btn-sm btn-primary" onClick={() => this.toggleLang()} disabled={isBaseNotAvail}><ATMIcon icon="plus" /></button>
                </WithHelperText>
                <WithHelperText tooltip="Export language files">
                    <LinkContainer to="/export">
                        <button className="app-btn btn-sm btn-primary" disabled={isBaseNotAvail}><ATMIcon icon="file-export" /></button>
                    </LinkContainer>
                </WithHelperText>
                <ModalPopup
                    size="lg"
                    nofooter
                    label="Add New Language"
                    show={this.state.showAddLang}
                    onHide={() => this.toggleLang()}>
                    <Row>
                        <Col sm={12} md={{ span:10, offset: 1 }}>
                            <Form.Group controlId="add-input">
                                <Form.Label>Language</Form.Label>
                                <Form.Control as="select" focus={true} placeholder="Language name" value={this.state.languageCode} onChange={( e ) => this.setState({ languageCode: e.target.value })}>
                                    <option></option>
                                    { supportedLanguages.filter( l => !langsList.includes( l.code )).map( lang => <option key={lang.code} value={lang.code}>{lang.alias}</option> ) }
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <div className="clearfix"></div>
                        <br/><br/>
                        <Col sm={12}>
                            <Button className="app-btn btn-sm btn-success float-right"
                                onClick={() => this.addNewLang()}
                                disabled={!this.state?.languageCode?.length > 0}
                            ><ATMIcon icon="plus" /> Add Language</Button>
                        </Col>
                    </Row>
                </ModalPopup>
            </>
        );
    }

}

const mapStateToProps = state => {
    const { base, languages } = state.reducer;
    return { base, languages };
};

const mapDispatchToProps = dispatch => ({
    uploadFile: ( file, type ) => dispatch( uploadFile( file, type )),
    addLangFile: ( alias, code, base ) => dispatch( addLangFile( alias, code, base )),
    showNotif: ( obj ) => dispatch( toggleNotif( obj ))
});

export default connect( mapStateToProps, mapDispatchToProps )( HeaderLinks );