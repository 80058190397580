import { USER_LOGOUT } from '../actions/commonActions';
import {
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    RESET_AUTH_PROPS
} from '../actions/authActions';

const initialState = {};

export default ( state = initialState, action ) => {
    switch ( action.type ) {
    case LOGIN_SUCCESS:
    case LOGIN_FAILURE:
    case RESET_AUTH_PROPS:
        return { ...state, ...action.data };
    case USER_LOGOUT:
        localStorage.removeItem( 'persist:amorphic-translations-manager' );
        return initialState;
    default:
        return state;
    }
};