import React, { useState, useEffect } from 'react';
import { ListGroup } from 'react-bootstrap';

import ATMIcon from './ATMIcon';

const CollapsibleList = ( props ) => {
    const [ isExpanded, setExpansion ] = useState( false );

    useEffect(() => {
        setExpansion( props.isExpand );
    }, [props.isExpand]);

    const clickItem = ( obj ) => {
        props.itemClicked( obj, props.path );
        setExpansion( !isExpanded );
    };

    const isHighLightItem = () => {
        const { obj, selObj } = props;

        if ( selObj?.name === obj?.name ) {
            return true;
        }

        return false;
    };
    return (
        <>
            <ListGroup.Item action variant={isHighLightItem() ? 'warning' : 'light'} onClick={() => clickItem( props.obj )}>
                { !props.obj.nonEditable
                    ? <ATMIcon icon={ 'key' } className={ 'text-secondary' } />
                    : <ATMIcon icon={ isExpanded ? 'folder-open' : 'folder' } className={ 'text-info' } />
                }
                <span className="translation-key"> {props.obj.name}</span>
            </ListGroup.Item >
            { isExpanded && props.obj.children &&
                <div className="ml-4">
                    {props.displayList( props.obj, props.path )}
                </div>
            }
        </>
    );
};

export default CollapsibleList;