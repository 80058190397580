import React from 'react';

import ATMIcon from './ATMIcon';
import { WithHelperText } from './helperFns';

const FileUploadButton = ( props ) => {

    const uploadFile = ( e, type ) => {
        onFileChange( e, type );
    };

    const onFileChange = ( event, type ) => {
        const { files } = event.target;

        for ( const file of files ) {
            const reader = new FileReader();
            reader.onload = (( t ) => {
                return ( e ) => {
                    const obj = JSON.parse( e.target.result );
                    props.uploadFileType( obj, t );
                };
            })( type );
            reader.readAsText( file );
        }

    };

    const inputClick = ( fileType ) => {
        document.getElementById( `${fileType}` ).click();
    };

    return (
        <>
            <input type="file" multiple={props.isMultiple ? true : false} accept=".json" id={props.type} className="app-btn" style={{ display: 'none' }} onClick={( e ) => e.target.value = ''} onChange={( e ) => uploadFile( e, props.type )} />
            <WithHelperText tooltip={ props.text }>
                <button className="app-btn btn-sm btn-primary" disabled={props.isDisabled} onClick={() => inputClick( props.type )}><ATMIcon icon={ props.icon } /></button>
            </WithHelperText>
        </>
    );
};

export default FileUploadButton;