import React from 'react';
import { useSelector /* useDispatch */ } from 'react-redux';
import { Button, ListGroup,
    Row, Col } from 'react-bootstrap';
import CollapsibleList from '../utils/CollapsibleList';
import { WithHelperText } from '../utils/helperFns';

import ATMIcon from '../utils/ATMIcon';

const KeysList = ( props ) => {

    const { base } = useSelector(( state ) => ({
        base: state.reducer.base
    }));

    const itemClicked = ( obj, path ) => {
        props.itemClicked( obj, path );
    };

    const comparePaths = ( currentPath, path ) => {
        const currPath = currentPath?.split( '.' );
        const selPath = path?.split( '.' );

        if ( currPath.length <= selPath.length ) {
            return true;
        }

        return false;
    };

    const displayList = ( obj, path ) => {
        const selPath = props.path;
        const selObj = props.obj;
        return (
            <ListGroup variant="flush">
                {obj.children.map(( childObj, index ) => {
                    const pathName = path ? `${path}.${childObj.name}` : `${childObj.name}`;
                    const isPathExists = comparePaths( pathName, selPath );
                    const isExpand = ( isPathExists && selPath && ( selObj !== childObj )) ? selPath.indexOf( pathName ) !== -1 : false;
                    return (
                        <CollapsibleList
                            path={pathName}
                            selObj={props.obj}
                            isExpand={isExpand}
                            key={`${childObj.name}_${index}`}
                            obj={childObj}
                            itemClicked={( expandObj, expandPath ) => itemClicked( expandObj, expandPath )}
                            displayList={( dlist, expandPath ) => displayList( dlist, expandPath )}
                        />
                    );
                })}
            </ListGroup>
        );
    };

    const keyEnabled = () => {
        const { obj } = props;

        if ( obj && obj.children ) {
            const { children } = obj;
            return children !== undefined;
        }

        return false;
    };

    return (
        <>
            <Row noGutters={true}>
                <Col sm={12}>
                    <span>
                        <ATMIcon icon={props.isBaseOpen ? 'folder-open' : 'folder'} onClick={() => props.toggleBaseOpen()} className="text-info"></ATMIcon>
                        {!props.isBaseOpen ? `(${base.length} Base Folders)` : ''}
                    </span>
                    <div className="float-right">
                        {!props.isBaseOpen ?
                            <WithHelperText tooltip="Add Base Folder">
                                <Button className="btn btn-sm btn-info" disabled={props.isBaseOpen} onClick={() => props.addKey( true )}>
                                    <ATMIcon icon="folder-plus" />
                                </Button>
                            </WithHelperText>
                            : <>
                                <WithHelperText tooltip="Add Folder">
                                    <Button className="btn btn-sm btn-info" disabled={!keyEnabled()} onClick={() => props.addKey( true )}>
                                        <ATMIcon icon="folder-plus" />
                                    </Button>
                                </WithHelperText>
                                <WithHelperText tooltip="Add Key">
                                    <Button className="btn btn-sm btn-success btn-border-right" disabled={!keyEnabled()} onClick={() => props.addKey()}>
                                        <ATMIcon icon="key" />
                                    </Button>
                                </WithHelperText>
                            </>}
                    </div>
                </Col>
            </Row>

            {props.isBaseOpen &&
                <Row>
                    <Col>
                        <ListGroup variant="flush">
                            {base.map(( bObj, index ) => {
                                const pathName = bObj.name;
                                const selPath = props.path;
                                const isExpand = selPath ? selPath.indexOf( pathName ) !== -1 : false;
                                return (
                                    <CollapsibleList
                                        path={pathName}
                                        selObj={props.obj}
                                        obj={bObj}
                                        isExpand={isExpand}
                                        key={`${bObj.name}_${index}`}
                                        itemClicked={( expandObj, expandPath ) => itemClicked( expandObj, expandPath )}
                                        displayList={( dlist, expandPath ) => displayList( dlist, expandPath ) }
                                    />
                                );
                            })}
                        </ListGroup>
                    </Col>
                </Row>}
        </>
    );
};

export default KeysList;