import { createStore, compose, applyMiddleware } from 'redux';
import { createStateSyncMiddleware } from 'redux-state-sync';
import thunkMiddleware from 'redux-thunk';
import { persistStore, persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from '../modules/reducers';

import { USER_LOGOUT } from '../modules/actions/commonActions';
import { LOGIN_SUCCESS } from '../modules/actions/authActions';

export default function configureStore() {

    const reducer = persistCombineReducers({ key: 'amorphic-translations-manager', storage, blacklist: ['form'] }, rootReducer );

    const middlewares = [
        createStateSyncMiddleware({
            whitelist: [ LOGIN_SUCCESS, USER_LOGOUT ]
        }),
        thunkMiddleware
    ];

    const store = createStore( reducer, {}, compose(
        applyMiddleware( ...middlewares ),
        window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__({ maxAge: 5 }) : f => f
    )
    );

    const persistor = persistStore( store );

    if ( module.hot ) {
        module.hot.accept( '../modules/reducers', () => {
            const nextReducer = require( '../modules/reducers' ).default; // eslint-disable-line global-require
            store.replaceReducer( nextReducer );
        });
    }

    return { persistor, store };
}
