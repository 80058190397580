import React from 'react';
import { Breadcrumb, Alert, Row, Col, Button, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import EditTranslations from './EditTranslations';
import ATMIcon from '../utils/ATMIcon';

class UpdateTranslations extends React.Component {
    constructor( props ) {
        super( props );
        this.state = {
            desc: '',
            showDesc: false
        };
    }

    saveTrans( state, path ) {
        this.props.saveTrans( state, path );
    }

    toggleDesc = ( desc = '' ) => {
        this.setState(( prevState ) => ({
            desc: desc ? desc : '',
            showDesc: !prevState.showDesc
        }));
    }

    saveDesc = () => {
        const { desc } = this.state;

        this.props.saveDescription( desc );
        this.toggleDesc();
    }

    render() {
        const { obj = {}, selChain = [], path = '' } = this.props;
        const { desc, showDesc } = this.state;

        return (
            <>
                { selChain?.length > 0 && <Breadcrumb>
                    {selChain.map(( p, index ) => <Breadcrumb.Item key={`${p}_${index}`} active={ selChain.length - 1 === index}>{p}</Breadcrumb.Item> )}
                </Breadcrumb>}
                <Alert variant="light">
                    { showDesc ?
                        <Form.Group controlId="desc">
                            <Row>
                                <Col>
                                    <Form.Label>Description</Form.Label>
                                </Col>
                                <Col>
                                    <ATMIcon onClick={() => this.setState({ showDesc: false })} className="float-right text-danger" icon="times"/>
                                </Col>
                            </Row>
                            <Col sm={10}>
                                <Form.Control as="textarea" rows="2" value={desc} onChange={e => this.setState({ desc:  e.target.value })}/>
                            </Col>
                            <Col sm={10}>
                                <Button className="app-btn btn-sm btn-success float-right" onClick={() => this.saveDesc() } disabled={!desc}> Save Description </Button>
                            </Col>
                        </Form.Group>
                        :
                        <React.Fragment>
                            <b>Description: </b>
                            { obj.description || 'No description available' }
                            { ' ' }
                            <ATMIcon icon="pen" onClick={() => this.toggleDesc( obj.description )} />
                        </React.Fragment> }
                </Alert>
                { !obj?.nonEditable
                    ? <EditTranslations
                        obj={obj}
                        path={path}
                        saveTrans={( st ) => this.saveTrans( st )}
                    />
                    : <Alert variant="light">{obj.name} has { obj?.children?.length || 0 } child Id&apos;s </Alert>
                }
            </>
        );
    }

}

const mapStateToProps = state => {
    const { base, languages } = state.reducer;
    return { base, languages };
};

const mapDispatchToProps = ( _dispatch ) => ({
});

export default connect( mapStateToProps, mapDispatchToProps )( UpdateTranslations );

