import React from 'react';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Container, Row, Col, Form, Button,
    Jumbotron, Alert } from 'react-bootstrap';

import ModalPopup from '../../common/ModalPopup';
import ATMIcon from '../utils/ATMIcon';
import { WithHelperText } from '../utils/helperFns';
import { translator, resetTranslateProps } from '../../modules/actions/translateActions';

class EditTranslations extends React.Component {
    constructor( props ) {
        super( props );
        this.state = { };
    }

    componentDidMount() {
        this.setComponentStates();
    }

    setComponentStates = () => {
        const { path } = this.props;
        const { languages } = this.props;

        languages.forEach(( langObj ) => {
            const lang = langObj['alias'];
            this.setState({
                [lang]:  langObj['translations'][`${path}`]
            });
        });
    }

    componentDidUpdate( prevProps ) {
        if ( this.props.path !== prevProps.path ) {
            this.setComponentStates();
        }
    }

    updateLangText = ( e, lang ) => {
        this.setState({
            [lang] : e.target.value
        });
    }

    getAliasName = ( code ) => {
        return this.props?.languages?.filter( l => l.code === code )?.[0]?.alias || null;
    }

    callTranslator = ( closeOnError = false ) => {
        const { fromLang, toLang } = this.state;
        const fromText = this.state?.[ this.getAliasName( fromLang ) ];
        if ( fromText ) {
            this.props.translator( fromText, toLang, fromLang );
        } else {
            if ( closeOnError ){
                this.displayConvertorModal( false );
            }
            Swal.fire({
                icon: 'warning',
                title: 'No source text found',
                html: `Text for <b class="text-info">${this.getAliasName( fromLang )}</b> is empty.<br/> Please provide a source text for translation.`
            });
        }
    }

    displayConvertorModal = ( display = false, source_language, target_language, callback ) => {
        this.setState({
            displayConvertorModal: display,
            fromLang: source_language,
            toLang: target_language
        }, () => {
            if ( display ) {
                this.props.resetTranslateProps({
                    fetching: false,
                    translatedText: undefined,
                    translateError: undefined
                });
            }
            if ( typeof callback === 'function' ){
                callback();
            }
        });
    }

    invokeTranslation = ( fromLang, toLang ) => {
        this.displayConvertorModal( true, fromLang, toLang, () => {
            this.callTranslator( true );
        });
    }

    setLangSelection( langType, value ){
        this.setState({ [langType]: value });
    }

    createnotify = () => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 5000
        });
        Toast.fire({
            type: 'info',
            title: 'Translation copied'
        });
        this.displayConvertorModal( false );
    }

    sortedLanguages = ( languages ) => {
        const filterLangs = languages.filter(( lang ) => !lang.preferred );
        const preferredLanguage = languages.find( lang => lang.preferred );
        const sortedLangs = filterLangs.sort(( a, b ) => ( a.alias > b.alias ) ? 1 : -1 );

        return [ preferredLanguage, ...sortedLangs ];
    }

    render() {
        const { languages, translate } = this.props;
        const { fromLang, toLang, displayConvertorModal } = this.state;

        const preferredLanguage = languages.find( lang => lang.preferred );
        const sortedLangs = this.sortedLanguages( languages );

        return (
            <>
                <div className="clearfix"></div>
                <Row className="mb-2" sm={12}>
                    <Col>
                        <div className="float-right float-top">
                            <WithHelperText tooltip="Conversion Helper">
                                <Button className="btn btn-primary btn-xs btn-small" onClick={() => this.displayConvertorModal( true )}>
                                    <ATMIcon icon="language" size="2x" />
                                </Button>
                            </WithHelperText>
                            <WithHelperText tooltip="Save Translations">
                                <Button className="app-btn btn btn-success btn-sm" onClick={() => this.props.saveTrans( this.state )} disabled={ translate?.fetching }>
                                    <ATMIcon icon="save"/>
                                </Button>
                            </WithHelperText>
                        </div>
                    </Col>
                </Row>
                <Container className="mt-2 fixed-container">
                    {sortedLangs.map(( lang, index ) => {
                        return <Form.Group as={Row} controlId={lang.code} key={`${lang.code}_${index}`}>
                            <Form.Label column sm={2}>
                                {lang.alias}{ ' ' }
                                { ( preferredLanguage?.code && preferredLanguage?.code === lang.code ) && <WithHelperText tooltip="Preferred language">
                                    <ATMIcon icon="star" className="text-success" />
                                </WithHelperText>
                                }
                                { ( preferredLanguage?.code && preferredLanguage?.code !== lang.code ) && <WithHelperText tooltip={`Translate from ${preferredLanguage.alias} to ${lang.alias}`}>
                                    <button className="btn btn-xs btn-light btn-link" onClick={() => this.invokeTranslation( preferredLanguage.code, lang.code )}><ATMIcon icon="language" /></button>
                                </WithHelperText>
                                }
                            </Form.Label>
                            <Col sm={10}>
                                <Form.Control as="textarea" rows="2" value={this.state[`${lang.alias}`]} onChange={( e ) => this.updateLangText( e, lang.alias )} />
                            </Col>
                        </Form.Group>;
                    })}
                </Container>

                <ModalPopup
                    label="Convert Text"
                    size="lg"
                    show={displayConvertorModal}
                    nofooter
                    onHide={() => this.displayConvertorModal( false )}>
                    { translate?.translateError
                        ? <Jumbotron>
                            <h3>Translation Error</h3>
                            { translate?.translateError }
                        </Jumbotron>
                        : <React.Fragment>
                            <Form.Row>
                                <Form.Group as={Col} controlId='fromLang'>
                                    <Form.Label column sm={2}>From</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control as="select" onChange={( e ) => this.setLangSelection( 'fromLang', e?.target?.value )} value={ fromLang } disabled={ translate?.fetching }>
                                            <option></option>
                                            { languages.map(( lang, index ) => <option key={index} value={lang.code}>{lang.alias}</option> )}
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Col} controlId='toLang'>
                                    <Form.Label column sm={2}>To</Form.Label>
                                    <Col sm={10}>
                                        <Form.Control as="select" onChange={( e ) => this.setLangSelection( 'toLang', e?.target?.value )} value={ toLang } disabled={ translate?.fetching }>
                                            <option></option>
                                            { languages.map(( lang, index ) => <option key={index} value={lang.code}>{lang.alias}</option> )}
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                            </Form.Row>
                            <Row>
                                { this.state?.[ this.getAliasName( fromLang ) ]?.length > 0 &&
                                    <Col sm={12}>
                                        <Alert variant="secondary">{ this.state?.[ this.getAliasName( fromLang ) ] }</Alert>
                                        <center><ATMIcon icon="angle-double-down" size="2x" /></center>
                                        { translate?.translatedText ? <Alert variant="success">
                                            <span dangerouslySetInnerHTML={{ __html: translate?.translatedText }} />
                                            <br/>
                                            <CopyToClipboard text={translate?.translatedText}>
                                                <button className="btn btn-sm btn-info float-right" title="Copy to Clipboard & close modal" onClick={() => this.createnotify()}><ATMIcon icon="copy" />&nbsp; Copy & close</button>
                                            </CopyToClipboard>
                                        </Alert> : <Alert variant="success">
                                            { translate?.fetching ? <ATMIcon icon="sync" spin className="text-primary" size="2x" /> : '?????????????????????'}
                                        </Alert> }
                                    </Col>
                                }
                            </Row>
                            <Row>
                                <div className="clearfix"></div>
                                <br/><br/>
                                <Col>
                                    <button className="app-btn btn-sm btn-success float-right"
                                        onClick={() => this.callTranslator()}
                                        disabled={ !fromLang?.length > 0 || !toLang?.length > 0 || translate?.fetching }
                                    >
                                        Translate
                                    </button>
                                </Col>
                            </Row>
                        </React.Fragment>
                    }
                </ModalPopup>

            </>
        );
    }

}
const mapStateToProps = ( state, _props ) => {
    return {
        base: state.reducer.base,
        languages: state.reducer.languages,
        translate: state.translate
    };
};

const mapDispatchToProps = ( dispatch ) => ({
    translator: ( text, target, source ) => dispatch( translator( text, target, source )),
    resetTranslateProps: ( propsToReset ) => dispatch( resetTranslateProps( propsToReset ))

});

export default connect( mapStateToProps, mapDispatchToProps )( EditTranslations );

