import React from 'react';
import Swal from 'sweetalert2';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import '../../styles/toast.css';

export const WithHelperText = ({ tooltip, children, placement = 'bottom' }) => {
    return (
        <>
            <OverlayTrigger
                key={placement}
                placement={placement}
                overlay={<Tooltip id={`tooltip-${placement}`}>{tooltip}</Tooltip>}
            >
                {children}
            </OverlayTrigger>{' '}
        </>
    );
};

export const showToast = (
    type = 'info',
    title = '',
    confirmButtonText = '',
    cancelButtonText = 'X',
    confirmAction = () => {},
    position = 'top-end'
) => {
    Swal.fire({
        icon: type !== 'loading' ? type : '',
        title,
        toast: true,
        position,
        showConfirmButton: confirmButtonText.length > 0,
        showCancelButton: cancelButtonText.length > 0 && type !== 'loading',
        timer: type === 'loading' ? 60000 : 5000,
        timerProgressBar: true,
        confirmButtonText,
        cancelButtonText,
        onOpen: () => {
            if ( type === 'loading' ) {
                Swal.showLoading();
            } else {
                Swal.hideLoading();
            }
        }
    }).then(( result ) => {
        if ( result.value ) {
            confirmAction();
        } else {
            Swal.close();
        }
    });
};
